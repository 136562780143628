import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga10Page from "~/components/Enga/Enga10Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est faire en sorte que toutes nos douches et WC soient équipés de systèmes toujours plus économes en eau.",
    },
  };

  return (
    <>
      <Title>Engagement Cogedim - Économiser l’eau</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga10Page />
    </>
  );
}
